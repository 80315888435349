<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2 mb-5" style="width: 100%">

          <!-- Left-aligned content -->
          <div class="d-inline-flex align-center">
            <v-switch
              v-model="activeOnly"
              data-cy="switch-active-only"
              :label="$lang.labels.activeOnly"
              class="pl-5 pr-3"
              inset
              dense
            ></v-switch>
            <v-text-field
              v-model="search"
              class="custom-search-perm"
              hide-details
              outlined
              clearable
              dense
              :label="$lang.labels.searchByName"
              data-cy="search-by-name"
            ></v-text-field>
          </div>

          <!-- Right-aligned buttons -->
          <div class="d-inline-flex align-center ml-auto">
            <v-btn
              text
              light
              class="ml-2"
              color="primary"
              @click="$emit('fetchUsers', { options: options, search: search && search.length > 1 ? search : '', resetPage: true })"
            >
              <v-icon dense small>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              v-if="roleChecker(roles, ['USER_CREATOR', 'SUPER_USER'])"
              color="accent"
              style="width: 80px;"
              class="color-accent-text mr-1"
              data-cy="create-user"
              :to="{ name: 'userCreate', params: { lang: $lang.current_lang } }"
            >
              {{ $lang.actions.create }}
            </v-btn>
          </div>

        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div class="custom-active-deactive">
          <p :class="{'custom-active': item.status === 'ACTIVE', 'custom-deactivated': item.status !== 'ACTIVE', 'm-0 p-0 mb-0': true }">
            {{ $lang.status[item.status] }}
          </p>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex justify-end" >
          <!-- Separate Edit Button -->
          <v-btn
            text
            color="primary"
            :to="{ name: 'userEdit', params: { id: item.id } }"
          >
            <v-icon small class="mr-1">mdi-lead-pencil</v-icon>
            {{ $lang.actions.edit }}
          </v-btn>

          <!-- Button with mdi-dots-vertical Icon -->
          <v-menu class="custom-edit-menu" offset-y nudge-left="95" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                variant="text"
                icon
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <!-- Menu with actions -->
            <v-card class="pr-2 pl-1" style="border: #CBD5E1 1px solid ;">
              <v-list dense>

                <!-- Deactivate/Activate button -->
                <v-list-item
                  link
                  class="text--secondary font-weight-light"
                  @click="$emit('changeStatus', {id: item.id, status: item.status})"
                >
                  {{ item.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-if="yesNoShow" v-model="yesNoShow" max-width="30%">
      <yes-no-modal :title="$lang.actions.changeStatus" :action-text="$lang.header.sureChangeStatus" @submit="changeStatusAfterConfirmation()" @closeDialog="tempItem = null; yesNoShow = false"></yes-no-modal>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import { roleChecker } from '@/utils/helpers'
import YesNoModal from '@/components/ui/modals/YesNoModal.vue'
import { debounce } from 'lodash'

export default {
  components: {
    YesNoModal
  },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      yesNoShow: false,
      tempItem: null,
      lock: true,
      activeOnly: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.header.email, value: 'email' },
        { text: this.$lang.labels.status, value: 'status', sortable: false }
      ],
      roles: []
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchUsers', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '' })
        this.savePreFill()
      },
      deep: true
    },
    search: {
      handler: debounce( function (val) {
        if (!this.lock && val && val.length > 1) this.$emit('fetchUsers', { options: this.options, search: val, resetPage: true })
        if (!this.lock && val === null || val === '') this.$emit('fetchUsers', { options: this.options, search: '', resetPage: true })
        this.savePreFill()
        this.options.page = 1
      }, 500)
    },
    activeOnly: {
      handler (val) {
        if (!this.lock) this.$emit('activeOnly', { activeOnly: val, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true } })
        this.savePreFill()
        this.options.page = 1
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchUsers', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      this.options.page = 1
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(3, 0, { text: this.$lang.labels.createdOn, value: 'createdOn' })
    }

    // eslint-disable-next-line consistent-return
    this.headers.forEach((item, index) => {
      if (item.value === 'actions') {
        if (this.userSettings.display.showModifiedOn) {
          this.headers.splice(index - 1, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn' })
        }
      }
    })

    if (this.roleChecker(this.roles, ['USER_CREATOR', 'SUPER_USER'])) {
      this.headers.push({ text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.userList) {
        this.search = preFill.userList.search
        this.activeOnly = preFill.userList.activeOnly
        this.options = preFill.userList.options

        this.$emit('activeOnly', { activeOnly: this.activeOnly, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true } })
      } else {
        this.$emit('fetchUsers', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      }
    } else {
      this.$emit('fetchUsers', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
    }
    setTimeout(() => this.lock = false, 100)
    this.options.page = 1
  },
  methods: {
    changeStatusAfterConfirmation() {
      this.$emit('changeStatus', { id: this.tempItem.id, status: this.tempItem.status })
      this.tempItem = null
      this.yesNoShow = false
    },
    roleChecker,
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.userList) preFill.userList = {
          search: '',
          activeOnly: true,
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.userList.search = this.search
        preFill.userList.activeOnly = this.activeOnly
        preFill.userList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    }
  }
}
</script>
